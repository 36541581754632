export const END_POINT = {
    SETTINGS: {
        SETTING_END_POINT: '/setting',
        GET_DYNAMIC_FORM_INFO: '/get/info',
        GET_ALL_TABLE_DATA: '/get/all',
        CREATE_DATA: '/save',
        GET_TABLE_COLUMN_DEFINITION: '/table/schema',
        GET_CHANGE_STATUS: '/changestatus',
        EXPORT: '/export'
    },
    SERVICE_TYPE: {
        STEPPER_DATA: '/order/stepper',
        GET_SERVICE_TYPE: '/patentservice/types'
    },
    COMPANY_DETAILS: {
        GET_COMPANY_LIST: '/company/getCompaniesByUser',
        GET_CONTACT_USER_LIST: '/company/users',
        GET_PATENT_DETAILS: '/patentservice/patentdetails'
    },
    SELECT_COUNTRY: {
        GET_COUNTRIES: '/patentservice/countries'
    },
    SUMMARY: {
        GET_SUMMARY: '/order/summary_v2',
        GET_CURRENCY: '/user/requestcurrencies'
    },
    PVSETTINGS: {
        GET_CURRENCY_EXCHANGE_DATA: '/currencyexchangelist',
        CREATE_CURRENCY_EXCHANGE: '/savecurrencyexchange',
        UPDATE_CURRENCY_EXCHANGE: '/updatecurrencyexchange',
        DELETE_CURRENCY_EXCHANGE_RECORD: '/delete',
        GET_CURRENCY_LIST: '/currencylist',
        GET_ADDRESS_INFORMATION: '/address',
        UPDATE_ADDRESS_INFORMATION: '/updateAddress',
        GET_SOURCE_LANGUAGES: '/sourceLanguages',
        GET_REQUEST_CURRENCY: '/requestcurrencies',
        GET_IP_RATES_LIST: '/iprates',
        GET_COUNTRY_LIST: '/patentvaultsetting/customcountries',
        GET_IP_UNITS: '/patentvaultsetting/units',
        VALIDATE_FORMULA: '/patentvaultsetting/validateformula',
        UPDATE_FEE: '/updatefee',
        UPDATE_ENGLISH_TRANSLATION_RATE: '/updateiprate',
        UPDATE_COMPANY_ENGLISH_TRANSLATION_RATE: '/updatecompanyiprate',
        IP_RATE_DETAIL: '/updateipratedetail',
        COMPANY_IP_RATE_DETAIL: '/company/updatecompanyipratedetail',
        GET_FORMULA_FIELDS: '/patentvaultsetting/formulafields',
        GET_COMPANY_TRANSLATIONS_LIST: '/companytranslationrates',
        GET_USER_LANGUAGE: '/user/languages',
        GET_ALL_CURRENCIES: '/currencylist',
        GET_VENDOR_TRANSLATIONS_LIST: '/vendortranslationrates',
        SAVE_COMPANY_TRANSLATIONS: '/savecompanytranslationrate',
        SAVE_VENDOR_TRANSLATIONS: '/savevendortranslationrate',
        DELETE_COMPANY_TRANSLATIONS: '/deletecompanytranslationrate',
        DELETE_VENDOR_TRANSLATIONS: '/deletevendortranslationrate',
        GET_COMPANY_LEVEL_TRANSLATION_DATA: '/companyleveltranslationrates',
        GET_PV_BREAKDOWNS: '/pvbreakdowns',
        RESET_TO_DEFAULT: '/iprateresettodefault'
    },
    PATENT_SERVICE: {
        GET_COMPANY_LIST: '/countries'
    },
    PV_SETTINGS_END_POINT: '/patentvaultsetting',
    PATENT_SERVICE_END_POINT: '/patentservices',
    COMPANIES: {
        GET_ALL_TABLE_DATA: 'company/list',
        PATCH_ACTIVATE_STATUS: 'company/activate',
        BASE: 'company',
        EXPORT: 'company/export',
        GET_COMPANY_DETAILS: 'getCompanyDetails',
        GET_CURRENCY: 'requestcurrencies',
        GET_COMPANY_RATES: 'rates',
        UPDATE_COMPANY_FEE: 'updateCompanyRateFee',
        GET_PREFERRED_CURRENCY: 'preferredcurrency',
        SEARCH_SAGE_COMPANY_LIST: 'searchcompanies',
        PARENT_COMPANY_LIST: 'parentcompanies',
        GET_SALES_REP_LIST: 'getsalesrep',
        GET_BILLING_TERMS_LIST: 'billingTerms',
        GET_PAYMENT_TYPES_LIST: 'paymenttypes',
        GET_INDUSTRIES_LIST: 'industries',
        GET_PREFERRED_CURRENCY_LIST: 'requestcurrencies',
        GET_BILLING_PERIOD_LIST: 'billingperiods',
        GET_COUNTRIES_LIST: 'countries',
        GET_STATES_LIST: 'states',
        SAVE_COMPANY_IP_RATES: '/updatecompanyleveltranslationrate',
        CHECK_EXISTING_COMPANY: 'existingcompany',
        SEARCH_TOP_LEVEL_COMPANY_LIST: 'searchtoplevelcompanies',
        UPDATE_COMPANY_IP_RATE: 'updatecompanyiprate',
        SYNC_COMPANYID: 'synccompanybyid',
        SYNC_COMPANYALL: 'synccompanyall'
    },
    ORDERS_AND_QUOTES_END_POINT: {
        GET_COUNTRIES: '/user/countries',
        GET_LANGUAGE: '/patentservice/getlanguages',
        GET_ORDER_AND_QUOTE_LIST: '/order/listv2',
        GET_INVOICE_LIST: '/order/invoice',
        UPDATE_INVOICE_FIELDS: '/order/updateinvoiceeditdetails',
        UPDATE_INVOICE_QUANTITY_RATE_FIELDS: '/order/updateinvoicequantityeditdetails',
        GET_BILLS_LIST: '/order/bills',
        GET_SUMMARY_LIST: '/order/summary',
        UPDATE_BILLS_FIELDS: '/order/updatebillseditdetails',
        ACTIVE_INACTIVE_QUOTE: '/order/changeStatus',
        EXPORT_ORDER_AND_QUOTE: '/order/exportv2',
        GET_REQUEST_STATUS: '/task/status',
        GET_ORDERS_DETAILS: '/getOrderDetailsView',
        GET_STANDARD_DETAILS: '/standardorder/orderdetailsview',
        GET_COUNTRY_DETAILS: '/getcountrydetails',
        GET_LANGUAGE_DETAILS: '/standardorder/getlanguagedetails',
        UPDATE_ORDER_AND_QUOTE_STATUS: '/requestchange',
        QUOTE_APPROVAL: '/quoteapproval',
        GET_SOURCE_DOCUMENTS: '/getsourcedocuments',
        GET_FILED_DOCUMENTS: '/fileddocuments',
        GET_TASK_ID: '/taskdetails',
        GET_TASK_DOCUMENT_STATUS: '/filedstatusstatus',
        UPDATE_TASK_DOCUMENT_STATUS: '/documentstatus',
        GET_FORMAL_DOCUMENTS: '/formaldocuments',
        GET_FORMAL_DOCUMENTS_COUNTRY_LIST: '/targetcountriesbyorderid',
        GET_FORMAL_DOCUMENT_STATUS: '/poastatus',
        UPDATE_FORMAL_DOCUMENT_STATUS: '/formalpoastatus',
        GET_FORMAL_DOCUMENT_HARDCOPY_STATUS: '/hardcopystatus',
        UPDATE_FORMAL_DOCUMENT_HARDCOPY_STATUS: '/formalhardcopydocumentstatus',
        MEMOQ_FILE_UPLOAD: '/order/bill/breakdown/calculate',
        STANDARD_MEMOQ_FILE_UPLOAD: '/standardorder/invoice/breakdown/calculate',
        PROJECT_MANAGER_LIST: '/order/projectmanager',
        POA_DOCUMENT_REMINDER_SEND: '/sendremainder',
        GET_FINAL_DOCUMENT: '/finaldocuments',
        EXPORT_EXCEL: '/order/excel/download',
        UPDATE_STATUS_PUBLISH_TO_CUSTOMER: '/task/recalldocuments',
        CHECK_FEES_VALIDATION_IP: '/order/checkBillsInvoiceFeesHasZero',
        CHECK_FEES_VALIDATION_NON_IP: '/order/checkCountryFeesHasZero',
        UPDATE_TASK_INVOICE_STATUS: '/order/updateTaskDetailsInvoice',
        EDIT_STEPPER_ORDER_QUOTE: 'order/getordereditdetailsview',
        EDIT_STEPPER_STANDARD: '/standardorder/getordereditdetailsview',
        STANDARD_SAVE_DRAFT: '/standardorder/draft',
        STANDARD_SUBMISSION: '/standardorder/quote',
        STANDARD_DELETE_RECORD: '/standardorder/languagesection',
        VENDOR_INVOICE_DOCUMENTS: '/requestbill/vendorinvoicedocuments',
        STD_PREVIEW: 'standardorder/save/preview/type/'
    },
    GET_DISCLAIMERS: '/summarydisclaimer',
    ORDER_SAVE: '/order',
    STANDARD_ORDER_SAVE: '/standardorder',
    ORDER_SAVE_DRAFT: '/order/save/draft',
    ORDER: '/order',
    TASKMANAGEMENT_END_POINT: {
        GET_ORDER_REQUEST_LIST: '/task/listv2',
        GET_TASK_DETAIL_LIST: '/task/listv2/taskdetails',
        GET_VENDOR_TASK_LIST: '/task/list/vendor',
        GET_ASSIGNEE: '/task/taskassignees',
        GET_VENDOR: '/task/VendorUsers',
        GET_TASK_DETAILS: '/task',
        GET_TASK_STATUS: '/task/statuschange',
        UPDATE_VENDOR_ASSIGNEE: '/task/vendor/assign',
        TASK_STATUS: '/task/status',
        GET_MANUAL_TASK_DETAILS: '/task/taskdetails',
        CREATE_MANUAL_TASK: '/task/savetasks',
        UPDATE_TASK_DETAILS: '/task/updatetaskdetails',
        TASKNAME: '/task/taskname',
        TARGET_COUNTRY: '/task/countries/',
        TARGET_LANGUAGE: '/task/languages/',
        UPDATE_TASK: '/task/updatetask'
    },
    USERS: {
        BASE: 'user',
        GET_ALL_TABLE_DATA: 'user/users/listv2',
        GET_USER_DATA: 'user/users',
        PATCH_ACTIVATE_STATUS: 'user/inactivate',
        EXPORT: 'user/export',
        VENDOR_MINIMUM_CHARGE_LIST: 'user/vendorMinimumCharges',
        PATCH_ACTIVATE_VENDOR_STATUS: 'user/inActivateVendorMinimumCharge',
        SAVE_MINIMUM_CHARGE: 'user/savevendorminimumcharge',
        GET_VENDOR_RATE_LIST: 'user/vendorRates',

        VENDOR_RATE_ACTIVE_STATUS: 'user/inActivateVendorRates',
        SAVE_VENDOR_RATE: 'user/saveVendorRates',
        VENDOR_MINIMUM_CHARGE_LIST_EXPORT: 'user/exportVendorMinimumCharge',
        UPDATE_MINIMUM_CHARGE: 'user/updateVendorMinimumCharge',
        VENDOR_RATE_EXPORT: 'user/exportVendorRates',
        VENDOR_CURRENCY: 'user/vendorcurrency',
        VENDOR_CURRENCIES: 'user/vendorcurrencies',
        CHECK_DUPLICATE_USER: 'user/existingemail',
        SYNC_USERID: 'syncuserbyid',
        SYNC_USERALL: 'syncuserall',
        SEARCH_VENDOR_USER: 'user/searchvendor'
    },
    BREAK_DOWN: {
        BASE: 'breakdown'
    },
    DASHBOARD: {
        GETTOKEN: '/dashboard/guesttoken'
    },
    ACCOUNTING: {
        GET_BILL_LIST: 'requestbill/list',
        GET_BILL_DETAILS: 'requestbill',
        EXPORT_BILL_LIST: 'requestbill/export',
        GET_INVOICE_LIST: 'requestinvoice/list',
        GET_INVOICE_DETAILS: 'requestinvoice',
        EXPORT_INVOICE_LIST: 'requestinvoice/export',
        SYNC_BILLID: 'syncbillbytaskid',
        SYNC_BILLALL: 'syncbillall',
        SYNC_INVOICEID: 'syncinvoicebyorderid',
        SYNC_INVOICEALL: 'syncinvoiceall'
    },
    ACCOUNT_SETTINGS: {
        GET_USER_DETAILS: '/user/userprofiles'
    },
    USER_FEEDBACK: '/user/feedback',
    USER_REGISTRATION: '/user/registrationform'
};
